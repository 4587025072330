<template>
  <div class="license-management">
    <BuyingLicenseWarningModal ref="BuyingLicenseWarningModal" />
    <EditLicensesModal :game="ownership.operatorGame" ref="EditLicensesModal" />
    <ContentBlock>
      <template #instructions>
        <h5>{{ $t('Pricing') }}</h5>
        <i18n
          tag="p"
          path="Manage your licenses for this title. Per minute licenses are paid at the end of the month based on your usage, whereas monthly and yearly licenses are “floating”, paid in advanced and renew automatically. Prices are in USD. Learn more by reading our {link}"
        >
          <template #link>
            <UIButton
              link
              target="_blank"
              rel="noopener"
              href="https://svr.li/k/218"
            >{{ $t('Commercial License Pricing Guide.') }}</UIButton>
          </template>
        </i18n>
      </template>
      <div class="contentwrapper f-body">
        <div>
          <UIButton
            v-if="canEditLicense"
            class="editcta center mb4-m mb5"
            style="display:block"
            tiny
            icon="edit"
            @click="showEditLicenseModal"
          >{{ $t('Edit Licenses') }}</UIButton>
          <ColumnTable class="licensetable tc w-100" :vertical="$mq.current === 'phone'">
            <Column
              class="tablecell w-33-m mb0-m ph2"
              :class="
              [
                `column${license.type}`,
                i === 1 ? 'br-m bl-m b--geyser' : '',
                i === licenses.length - 1 ? 'mb0' : 'mb5',
                !license.available ? '-disabled' : '',
              ].join(' ')
            "
              v-for="(license, i) in licenses"
              :key="license.name"
            >
              <ColumnHeader>
                <h3 class="tableheader f-tinycta mt4" :class="`tableheader${license.type}`">
                  <template v-if="license.hasRecentlyChanged && !license.percentageOff">
                    <span class="blue">{{ $t('New') }}</span> -
                  </template>
                  {{ license.name }}
                </h3>
              </ColumnHeader>
              <ColumnRow>
                <div class="pv4-m pv3">
                  <h4
                    class="f2 relative dib"
                    :class="{[`priceper${license.type}`]: true, blue: license.percentageOff}"
                  >
                    <UIIcon
                      v-if="!license.available"
                      name="error"
                      style="width:2.8rem;height:2.8rem;display:inline-block;vertical-align:-5px;"
                    />
                    <template v-else-if="license.price">
                      {{
                      license.displayPrice
                      }}
                    </template>
                    <template v-else>{{ $t('Free') }}</template>
                    <span
                      class="pricebeforediscount f-label absolute right-0 strikethrough"
                      style="transform: translate(calc(100% + 5px));"
                      v-if="license.percentageOff"
                    >{{ license.priceBeforeDiscount }}</span>
                  </h4>
                  <p
                    class="blue f-label mt3"
                    :class="`pricechange${license.type}`"
                    v-if="license.priceChange"
                  >
                    <template v-if="license.percentageOff">
                      <span
                        v-if="license.percentageOff === '100%'"
                      >{{ $t('Free until {date}', { date: license.discountDisplayEndDate}) }}</span>
                      <span
                        v-else
                      >{{ $t('{percentage} Off until {date}', { percentage: license.percentageOff, date: license.discountDisplayEndDate }) }}</span>
                    </template>
                    <i18n v-else path="{newprice} starting {date}">
                      <template #newprice>
                        {{
                        license.priceChange.newPriceDisplay
                        }}
                      </template>
                      <template #date>
                        {{
                        license.priceChange.displayDateChange
                        }}
                      </template>
                    </i18n>
                  </p>
                </div>
              </ColumnRow>
              <ColumnRow>
                <div class="licensescount mb3">
                  <div class="f-body" :class="$mq.current === 'phone' && 'f-label fjord di'">
                    <template v-if="!license.available">
                      {{
                      $t('Not Available')
                      }}
                    </template>
                    <template v-else-if="license.type !== 'minute'">
                      {{
                      $tc(
                      '0 Licenses | 1 License | {count} Licenses',
                      license.count
                      )
                      }}
                    </template>
                    <p v-else class="f-label fjord di db-m">
                      {{
                      $t(
                      'Active: used when monthly or yearly licenses are not available'
                      )
                      }}
                    </p>
                    <span v-if="license.usage && license.available" class="di dn-m">
                      |
                      {{
                      $t('{count} min used this month', {
                      count: license.displayUsage,
                      })
                      }}
                    </span>
                  </div>
                  <p v-if="license.notRenewingCount" class="orange f-instructions mt3 mt2-m">
                    {{
                    $tc(
                    '1 license won’t renew | {count} Licenses won’t renew',
                    license.notRenewingCount
                    )
                    }}
                  </p>
                </div>
              </ColumnRow>
              <ColumnRow>
                <p class="f-label fjord pb4 dn db-m" v-if="license.usage && license.available">
                  {{
                  $t('{count} min used this month', {
                  count: license.displayUsage,
                  })
                  }}
                </p>
                <p class="f-body pb4 dn db-m" v-else-if="license.count">-</p>
              </ColumnRow>
            </Column>
          </ColumnTable>
        </div>
        <div
          class="flex f-tiny items-center justify-center"
          v-if="
            soonExpiringOrRenewingYearlyLicenses.length ||
              licensesByType.monthly.notRenewingCount
          "
        >
          <UIIcon name="alert-circle" style="width:2em;" class="rotate-180 dib fill-blue mr3" />
          <ul>
            <UseVuex v-slot="{ state }" v-if="licensesByType.monthly.notRenewingCount">
              <li
                v-html="
                  $tc(
                    '1 monthly license will expire on {date} | {count} monthly licenses will expire on {date}',
                    licensesByType.monthly.notRenewingCount,
                    { date: state.common.firstOfNextMonth + ' - UTC 12:00 am' }
                  )
                "
              ></li>
            </UseVuex>
            <li v-for="(item, i) in soonExpiringOrRenewingYearlyLicenses" :key="i">
              <template v-if="item.renewing">
                {{
                $tc(
                '1 yearly license will be renewed on {date} | {count} yearly licenses will be renewed on {date}',
                item.count,
                { date: formatDisplayDate(item.renewsAt, { utc: true }) + ' - UTC 12:00 am' }
                )
                }}
              </template>
              <template v-else>
                {{
                $tc(
                '1 yearly license will expire on {date} | {count} yearly licenses will expire on {date}',
                item.count,
                { date: formatDisplayDate(item.endsAt, { utc: true }) + ' - UTC 12:00 am' }
                )
                }}
              </template>
            </li>
          </ul>
        </div>
        <template v-if="!ownership.isFree">
          <p
            class="warningnoppm orange f-instructions tc center w-80"
            v-if="!(ownership.minutePrice && ownership.minutePrice.current)"
          >
            <UIIcon name="alert-triangle-fill" class="fill-error mr1 v-mid w2"></UIIcon>
            {{
            $t(
            'Per Minute Licenses are not available for this title. If all available monthly or yearly licenses are in use, the title will be momentarily hidden in the Launcher of the additional stations.'
            )
            }}
          </p>
          <div>
            <hr class="mb4 mt0 hrseparator" />
            <UIButton
              style="display:block;margin: 0 auto;"
              tiny
              secondary
              @click="isInvoiceExpanded = !isInvoiceExpanded"
            >
              {{ $t('Show Recurring Monthly Invoice') }}
              <UIIcon
                class="w-1 fill-fjord"
                name="chevron-down"
                :class="isInvoiceExpanded ? 'rotate-180' : ''"
              />
            </UIButton>
            <UIExpandable :expanded="isInvoiceExpanded" ref="upcominginvoice">
              <UpcomingInvoice class="upcominginvoice tl mt6-m mt4" :game="game" />
            </UIExpandable>
          </div>
        </template>
      </div>
    </ContentBlock>
  </div>
</template>

<script>
import Component from 'vue-class-component';
import Vue from 'vue';
import ContentBlock from '@/core/shared/components/ContentBlock';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UIExpandable from '@/core/shared/components/ui/UIExpandable';
import UpcomingInvoice from './UpcomingInvoice';
import BuyingLicenseWarningModal from './BuyingLicenseWarningModal';
import EditLicensesModal from './EditLicensesModal';
import { createModalWrapper } from '@/core/shared/helpers/HOCHelper';
import { get, keyBy } from 'lodash-es';
import UseVuex from '@/core/shared/components/utils/UseVuex';
import moment from 'moment';
import { hasPriceRecentlyChanged, formatDisplayDate, formatLicenses } from '@/core/helpers/FormatHelpers';

@Component({
  components: {
    ContentBlock,
    UIButton,
    UIIcon,
    UIExpandable,
    UpcomingInvoice,
    UseVuex,
    BuyingLicenseWarningModal: createModalWrapper(BuyingLicenseWarningModal),
    EditLicensesModal: createModalWrapper(EditLicensesModal),
    ColumnTable: {
      props: {
        vertical: { type: Boolean },
      },
      render (h) {
        const vNodes = this.$scopedSlots.default();
        const columns = vNodes;
        const rows = columns.map(({ children }) => children.slice(1));
        const headerRows = columns.map(({ children }) => children[0]);
        // vertical style
        if (this.vertical) {
          return h('div', [
            ...columns.map((column, i) => {
              const { class: classes, staticClass } = column.data || {};
              return h('div', {
                class: classes,
                staticClass,
                key: i,
              }, column.children.flatMap(({ children }) => children));
            }),
          ]);
        }
        // table style
        return h('table', [
          h('thead', [
            headerRows.map((headerRow, i) => {
              const column = columns[i];
              const { class: classes, staticClass } = column.data || {};
              return h('th', {
                class: classes,
                staticClass,
                key: i,
              }, headerRow.children);
            }),
          ]),
          h('tbody', Array(rows[0].length).fill(0).map((v, i) => {
            const cells = Array(columns.length).fill(0).map((v, j) => {
              const column = columns[j];
              const { class: classes, staticClass } = column.data || {};
              return h('td', {
                class: classes,
                staticClass,
                key: j,
              }, rows[j][i].children);
            });
            return h('tr', { key: i }, cells);
          })),
        ]);
      },
    },
  },
  props: {
    ownership: {
      required: true,
    },
  },
  methods: {
    hasPriceRecentlyChanged,
    formatDisplayDate,
  },
})
export default class LicenseManagement extends Vue {
  isInvoiceExpanded = false
  async showWarningModal () {
    return new Promise((resolve, reject) => {
      this.$refs.BuyingLicenseWarningModal.show(undefined, {
        confirm () {
          resolve(true);
        },
        closeModal () {
          resolve(false);
        },
      });
    });
  }

  async showEditLicenseModal () {
    if (!this.$store.getters.currentOrganization.hasOrderedMonthlyYearly) {
      const confirm = await this.showWarningModal();
      if (!confirm) return;
    }
    this.$refs.EditLicensesModal.show(undefined, {});
  }

  get game () {
    return this.ownership.operatorGame;
  }

  get canEditLicense () {
    // Don't edit licenses when the arcade is on trial
    if (this.$store.getters.currentOrganization.isOnTrial) {
      return false;
    }

    return get(this.ownership, 'monthlyPrice.current') || get(this.ownership, 'yearlyPrice.current');
  }

  get soonExpiringOrRenewingYearlyLicenses () {
    if (!this.ownership.operatorLicense.yearlyLicenses) return [];
    return this.ownership.operatorLicense.yearlyLicenses.filter(({ endsAt, renewsAt }) => {
      const msDiff = moment(endsAt || renewsAt).diff(moment());
      return moment.duration(msDiff).asMonths() <= 1;
    }).reduce((arr, license) => {
      const { endsAt, renewsAt, renewable } = license;
      const found = arr.find(item => item && item.renewing === renewable && (item.endsAt === endsAt || item.renewsAt === renewsAt));
      if (found) found.count++;
      else arr.push({ renewing: renewable, count: 1, endsAt, renewsAt });
      return arr;
    }, []);
  }

  get licensesByType () {
    return keyBy(this.licenses, 'type');
  }

  get licenses () {
    return formatLicenses(this.ownership);
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.license-management {
  & >>> .tablecell {
    &.-disabled {
      opacity: 0.3;
      & * {
        color: var(--colorSlate);
        fill: var(--colorSlate);
      }
    }
  }
  & .pricebeforediscount {
    top: 0;
    line-height: 1;
    &:after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      content: "";
      background-size: 100% auto;
      background-position: 50%;
      background-repeat: no-repeat;
      background-image: url('data:image/svg+xml; utf8, <svg viewBox="0 0 100% 100%" preserveRatio="none" xmlns="http://www.w3.org/2000/svg"><line x1="0" y1="40%" x2="100%" y2="60%" style="stroke:rgb(0,0,0);stroke-width:1"/></svg>');
    }
  }
  & .editcta {
    @media (--tablet) {
      margin-left: auto;
      margin-right: 0;
    }
  }
  & .contentwrapper {
    display: grid;
    row-gap: 3rem;
  }
}
</style>
