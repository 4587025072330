<template>
  <div class="marketplace">
    <div>
      <MarketplaceFeatured
        class="featuredgames"
        style="height: 400px;"
      ></MarketplaceFeatured>
    </div>
    <div class="filterpanel">
      <div class="filters">
        <UIInput
          :title="$t('Search')"
          type="search"
          v-model="search"
        />
        <UISelect
          :title="$t('Content Type')"
          v-model="filterContentTarget"
        >
          <option value="all">{{ $t('All') }}</option>
          <option value="entertainment">{{ $t('Entertainment') }}</option>
          <option value="education">{{ $t('Education') }}</option>
          <option value="narrative">{{ $t('Narrative') }}</option>
        </UISelect>
        <GenreMultiselect v-model="filterGenres"></GenreMultiselect>
        <UISelect
          :title="$t('Sort by')"
          v-model="selectedSort"
        >
          <option value="popularityOrder:ASC">{{ $t('Top Games') }}</option>
          <option value="title:ASC">{{ $t('A - Z') }}</option>
          <option value="title:DESC">{{ $t('Z - A') }}</option>
          <option value="createdAt:DESC">{{ $t('Newest') }}</option>
          <option value="createdAt:ASC">{{ $t('Oldest') }}</option>
          <option value="basePriceMinute:ASC">{{ $t('Lowest Price per Minute') }}</option>
          <option value="basePriceMinute:DESC">{{ $t('Highest Price per Minute') }}</option>
          <option value="basePriceMonth:ASC">{{ $t('Lowest Monthly Price') }}</option>
          <option value="basePriceMonth:DESC">{{ $t('Highest Monthly Price') }}</option>
          <option value="basePriceYear:ASC">{{ $t('Lowest Yearly Price') }}</option>
          <option value="basePriceYear:DESC">{{ $t('Highest Yearly Price') }}</option>
          <option value="recentlyUpdated:DESC">{{ $t('Most Recently Updated') }}</option>
        </UISelect>
        <UICheckbox
          v-model="filterCds"
        >
          <template #title>
            <i18n path="Show {link} only">
              <template #link>
                <a
                  href="https://svr.li/k/296"
                  target="_blank"
                  rel="noopener"
                  class="link-style"
                >{{ $t('One-Click Titles') }}</a>
              </template>
            </i18n>
          </template>
        </UICheckbox>
        <UICheckbox
          class="promotedcheckbox"
          v-model="filterPromoted"
        >
          <template #title>
            <span class="f-label b ph2 pv1 white bg-gradient-blue br3 mr1">% Off</span>
            <span>{{$t('Show only discounted titles')}}</span>
          </template>
        </UICheckbox>
        <UICheckbox
          class="regionalcheckbox"
          :title="$t('Show only regionally priced titles')"
          v-model="filterRegional"
        />
        <UICheckbox
          class="bundlecheckbox"
          :title="$t('Show only bundles')"
          v-model="filterBundles"
        />
      </div>
    </div>
    <div class="container">
      <div
        class="f-body tc mt4 w-60 center fill-geyser fadeIn animated"
        v-if="!isLoadingSearch && !marketplaceSorted.length"
      >
        <UIIcon
          name="no-results"
          class="dib mv5"
          style="width:8rem;"
        />
        <p>Sorry, we couldn’t find a title matching your search.</p>
        <i18n
          tag="p"
          path="Try updating your search or request new content on the {link}."
        >
          <template #link>
            <a
              class="link-style"
              href="https://features.springboardvr.com/commercial-licensing"
              rel="noopener"
              target="_blank"
            >Content Request Board</a>
          </template>
        </i18n>
      </div>
      <template v-else>
        <div
          class="trialbox pa5 ba b--geyser mt5 flex-m items-center"
          v-if="isOnTrial"
        >
          <div>
            <h3 class="f-tinycta fjord mb4">{{$t('Free Commercial Licensing While On Trial')}}</h3>
            <p
              class="f-body"
            >{{$t('The titles below are available to you completely free of charge while you’re on trial with SpringboardVR! Once your trial is over you will be charged the rates displayed.')}}</p>
          </div>
          <UIButton
            class="ml-auto mt4 mt0-m"
            target="_blank"
            rel="noopener"
            href="https://springboardvr.com/marketplace"
          >{{$t('View Full Marketplace')}}</UIButton>
        </div>
        <div
          class="trialbox pa5 ba b--geyser mt5 flex-m items-center"
          v-else-if="!hasMarketplaceAccess"
        >
          <div>
            <h3 class="f-tinycta fjord mb4">{{$t('Upgrade now to start playing')}}</h3>
            <p
              class="f-body"
            >{{$t('You can license titles through the Marketplace once you switch to the paid plan.')}}</p>
          </div>
          <UIButton
            v-if="!subscriptionExclusion"
            class="ml-auto mt4 mt0-m"
            :to="{ name: 'billing-account', query: { subscribe: 1 } }"
          >{{$t('Upgrade Now')}}</UIButton>
        </div>
        <p class="f-thead tc pv4">
          <span
            v-if="paginatorInfo"
            class="animated fadeIn"
          >
            {{ paginatorInfo.total > 1
            ? $t('{count} results found', { count: paginatorInfo.total })
            : $t('1 result found')
            }}
          </span>
          &nbsp;
        </p>
        <div
          class="cardgridcontainer fadeIn animated"
          :class="computedGridHeight && '-cropped'"
          :style="computedGridHeight && { overflow: 'hidden', maxHeight: `${computedGridHeight}px`}"
        >
          <div class="cardgrid fadeIn animated">
            <OwnershipCard
              class="card"
              usage="marketplace"
              :details="false"
              v-for="contentOwnership in marketplaceSorted"
              :key="contentOwnership.id"
              :content="contentOwnership"
            />
            <div
              class="cardcontainer"
              v-for="i in loadingCardPlaceholdersCount"
              :key="'placeholder:'+i"
            >
              <OwnershipCardPlaceholder
                class="bg-white w-100 h-100"
                :placeholder="true"
              />
              <VisibilityObserver
                @visible="fetchNextPage"
                v-if="marketplaceSorted.length"
              />
            </div>
            <!-- here we add empty placeholders - this prevent items to scale off in the grid - I'd rather do it in css but that is hard -->
            <template>
              <div
                v-for="i in 10"
                :key="'filler:'+i"
              >&nbsp;</div>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import OwnershipCard from '@/content/shared/OwnershipCard';
import OwnershipCardPlaceholder from '@/content/shared/OwnershipCardPlaceholder';
import Panel from '@/core/shared/components/Panel';
import { debounce } from 'lodash-es';
import UIInput from '@/core/shared/components/ui/UIInput';
import UISelect from '@/core/shared/components/ui/UISelect';
import UICheckbox from '@/core/shared/components/ui/UICheckbox';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UIButton from '@/core/shared/components/ui/UIButton';
import VisibilityObserver from '@/core/components/utils/VisibilityObserver';
import MarketplaceFeatured from './components/MarketplaceFeatured';
import GenreMultiselect from './components/GenreMultiselect';

@Component({
  name: 'MarketplaceRoute',
  components: {
    UIInput,
    UISelect,
    UICheckbox,
    UIIcon,
    UIButton,
    Panel,
    VisibilityObserver,
    OwnershipCard,
    OwnershipCardPlaceholder,
    MarketplaceFeatured,
    GenreMultiselect,
  },
  watch: {
    search: debounce(function () {
      this.fetchGamesWithSearch();
      this.$router.push({ name: this.$route.name, query: { ...this.$route.query, q: this.search } });
    }, 500),
    filters () {
      this.fetchGamesWithSearch();
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          filters: this.filters,
        },
      });
    },
  },
})
export default class MarketplaceRoute extends Vue {
  isLoading = false
  isLoadingSearch = false
  gridHeight = null
  get isOnTrial () {
    return this.$store.getters.currentOrganization.isOnTrial;
  }

  get hasMarketplaceAccess () {
    return this.$store.getters.currentOrganization.hasMarketplaceAccess;
  }

  get subscriptionExclusion () {
    return this.$store.getters.currentOrganization.subscriptionExclusion;
  }

  get search () { return this.$store.state.marketplace.filterOptions.search; }
  set search (search) { this.$store.commit('marketplace/set', { path: 'filterOptions.search', value: search }); }

  get filterGenres () { return this.$store.getters['entities/Genre/all']() && this.$store.state.marketplace.filterOptions.filterGenres && this.$store.state.marketplace.filterOptions.filterGenres.map(id => this.$store.getters['entities/Genre'](id)); }
  set filterGenres (val) { this.$store.commit('marketplace/set', { path: 'filterOptions.filterGenres', value: val.map(({ id }) => id) }); }

  get paginatorInfo () {
    return this.$store.state.marketplace.paginatorInfo;
  }

  get loadingCardPlaceholdersCount () {
    return this.paginatorInfo ? (this.paginatorInfo.hasMorePages && this.paginatorInfo.perPage) || 0 : 20;
  }

  get marketplaceSorted () {
    return this.$store.getters['entities/ContentOwnership'](this.$store.state.marketplace.contentOwnershipIds);
  }

  // here we trim the bottom of the grid if it loading to prevent the user to go all the way down and trigger another page fetch immidiately
  get computedGridHeight () {
    return this.gridHeight && this.isLoading && this.gridHeight - 300;
  }

  async created () {
    this.$store.commit('setTitle', this.$t('Marketplace'));
    this.fetchGamesWithSearch();
    this.setupResizeListener();
  }

  activated () {
    this.$store.commit('setTitle', this.$t('Marketplace'));
    this.setupResizeListener();
  }

  deactivated () {
    this.teardownResizeListener();
  }

  destroyed () {
    this.teardownResizeListener();
  }

  setupResizeListener () {
    this.__resizeHandler = this.computeGridHeight.bind(this);
    window.addEventListener('resize', this.__resizeHandler);
  }

  teardownResizeListener () {
    window.removeEventListener('resize', this.__resizeHandler);
  }

  computeGridHeight () {
    const gridEl = this.$el.querySelector('.cardgrid');
    this.gridHeight = gridEl ? gridEl.offsetHeight : null;
  }

  getMarketplace () {
    this.$store.dispatch('clearMarketplace');
    return this.$store.dispatch('getMarketplace');
  }

  async fetchGamesWithSearch () {
    this.isLoadingSearch = true;
    try {
      const data = await this.getMarketplace();
      if (data) this.isLoadingSearch = false;
    } catch (e) {
      this.isLoadingSearch = false;
      throw e;
    }
    await this.$nextTick();
    this.computeGridHeight();
  }

  async fetchNextPage () {
    const paginatorInfo = this.$store.state.marketplace.paginatorInfo;
    const { lastPage, currentPage } = paginatorInfo;
    if (lastPage !== currentPage && !this.isLoading) {
      try {
        this.isLoading = true;
        await this.$store.dispatch('getMarketplace', { page: currentPage + 1 });
      } finally {
        this.isLoading = false;
      }
      await this.$nextTick();
      this.computeGridHeight();
    }
  }

  set selectedSort (value) {
    const [sortType, sortDirection] = value.split(':');
    this.$store.commit('marketplace/set', { path: 'filterOptions.sortType', value: sortType });
    this.$store.commit('marketplace/set', { path: 'filterOptions.sortDirection', value: sortDirection });
  }

  get selectedSort () {
    return ['sortType', 'sortDirection'].map(k => this.$store.state.marketplace.filterOptions[k]).join(':');
  }

  set filterCds (value) {
    this.$store.commit('marketplace/set', { path: 'filterOptions.filterCds', value: value || undefined });
  }

  get filterCds () {
    return this.$store.state.marketplace.filterOptions.filterCds;
  }

  set filterPromoted (value) {
    this.$store.commit('marketplace/set', { path: 'filterOptions.filterPromoted', value: value });
  }

  get filterPromoted () {
    return this.$store.state.marketplace.filterOptions.filterPromoted;
  }

  set filterRegional (value) {
    this.$store.commit('marketplace/set', { path: 'filterOptions.filterRegional', value: value });
  }

  get filterRegional () {
    return this.$store.state.marketplace.filterOptions.filterRegional;
  }

  set filterBundles (value) {
    this.$store.commit('marketplace/set', { path: 'filterOptions.filterBundles', value: value });
  }

  get filterBundles () {
    return this.$store.state.marketplace.filterOptions.filterBundles;
  }

  set filterContentTarget (value) {
    this.$store.commit('marketplace/set', { path: 'filterOptions.filterContentTarget', value });
  }

  get filterContentTarget () {
    return this.$store.state.marketplace.filterOptions.filterContentTarget;
  }

  get filters () {
    return `${this.filterContentTarget}:${this.filterCds || ''}:${this.selectedSort}:${this.filterGenres ? JSON.stringify(this.$store.state.marketplace.filterOptions.filterGenres) : ''}:${this.filterPromoted || ''}:${this.filterRegional || ''}:${this.filterBundles || ''}`;
  }

  get hasFilterOn () {
    return this.filterContentTarget !== 'all' || this.filterCds || this.filterGenres?.length || this.filterPromoted || this.filterRegional || this.filterBundles || this.search;
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.marketplace {
  & .container {
    @apply --container;
  }
  & .filters {
    max-width: 120rem;
    margin: 0 auto;
    display: grid;
    grid-gap: var(--spacingSm);
    @media (--tablet) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (--desktop) {
      column-gap: var(--spacingMd);
      row-gap: var(--spacingSm);
    }
  }
  & .filterpanel {
    background-color: white;
    @apply --panelDropShadow;
    padding: 3rem;
    @media (--tablet) {
      padding: 2.4rem;
    }
  }

  & .cardgridcontainer {
    &:after {
      pointer-events: none;
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 350px;
      background: linear-gradient(
        to top,
        #f7f8f8 0%,
        #f7f8f8 20%,
        color-mod(#f7f8f8 alpha(0%)) 100%
      );
      opacity: 0;
    }
    &.-cropped:after {
      opacity: 1;
    }
    position: relative;
  }
  & .cardgrid {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: var(--spacingSm);
    justify-content: center;
    padding-bottom: var(--spacingMd);

    @media (--tablet) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (--desktop) {
      grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
    }
  }
  & .card,
  & .discountcard {
    height: 100%;
    transition: transform 0.22s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    &:hover {
      transform: translateY(-0.4rem);
      box-shadow: var(--shadowMd);
    }
  }
  & .discountcard {
    @apply --panelDropShadow;
  }
}
</style>
