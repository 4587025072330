<template>
  <Confirm
    class="buying-license-warning-modal show"
    icon="shopping-cart"
    :buttons="buttons"
    @closeConfirm="onCancel"
    v-bind="$props"
    v-on="$listeners"
    :show="true"
  >
    <template #content>
      <!-- <UIIcon name="shopping-cart" class="w3 fill-fjord" /> -->
      <h4 class="f-body b mb4">{{ $t('How to buy and cancel licenses')}}</h4>
      <div class="tl">
        <p
          class="mb4"
        >Monthly and yearly licenses are "floating". This means they are not assigned to specific stations and can be used on ANY station. If you run out of available monthly or yearly licenses, you will be charged per minute of gameplay on the additional stations running the title.</p>
        <p
          class="mb4"
        >Monthly and yearly licenses are paid in advance. Cancelled licenses are nonrefundable and can be used until they expire. Monthly licenses renew automatically on the 1st of each month. Yearly licenses renew automatically one year from the purchase date.</p>
        <i18n tag="p" class="mb4" path="Need more info? Read our {link}.">
          <template #link>
            <UIButton
              link
              target="_blank"
              rel="noopener"
              href="https://svr.li/k/218"
            >{{ $t('Commercial License Pricing Guide') }}</UIButton>
          </template>
        </i18n>
      </div>
    </template>
  </Confirm>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import Modal from '@/core/shared/components/Modal';
import Confirm from '@/core/shared/components/Confirm';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UIButton from '@/core/shared/components/ui/UIButton';

@Component({
  components: {
    UIIcon,
    UIButton,
    Modal,
    Confirm,
  },
})
export default class LicenceSourceModal extends Vue {
  internalValue = {
    licensingSource: null,
  }

  get buttons () {
    return [
      {
        name: this.$t('Start'),
        action: () => {
          return this.onSubmit();
        },
      },
    ];
  }

  get isValid () {
    return !!this.internalValue.licensingSource;
  }

  onSubmit () {
    this.$emit('confirm');
    return Promise.resolve();
  }

  onCancel () {
    this.$emit('cancel');
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.buying-license-warning-modal {
}
</style>
