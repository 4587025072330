<template>
  <div class="content-show-settings-route">
    <template v-if="localGame">
      <ValidationObserver ref="validationObserver">
        <BreadcrumbNavConnected class="mb4" />
        <ContentBlock
          :required="contentOwnership && !contentOwnership.cds && !!localGame.launchMethod"
        >
          <template #instructions>
            <h5>{{ $t('Launch Settings') }}</h5>
            <template v-if="contentOwnership && contentOwnership.cds">
              <p
                class="mt3"
              >{{ $t('Customize how our desktop software will launch this content by specifying any extra command line arguments to be used.') }}</p>
            </template>
            <template v-else>
              <p
                class="mt3"
              >{{ $t('Customize how our desktop software will launch this content by either specifying a Universal Resource Indicator or a file path.') }}</p>
              <p
                class="mt3"
              >{{ $t('A Universal Resource Indicator is for applications like Steam where you can provide a unique URL that will trigger the title to launch.') }}</p>
              <p
                class="mt3"
              >{{ $t('Path-based launch lets you specify the exact file path to the content you wish to launch.') }}</p>
            </template>
            <i18n tag="p" class="mt3" path="Read more about {link}.">
              <template #link>
                <UIButton
                  link
                  href="https://svr.li/k/346"
                  target="_blank"
                  rel="noopener"
                >{{ $t('Launch Settings') }}</UIButton>
              </template>
            </i18n>
          </template>
          <LaunchSettingsForm :storedGame="storedGame" :game="game" @edit="editGame" />
        </ContentBlock>
      </ValidationObserver>
    </template>
  </div>
</template>

<script>
import Component from 'vue-class-component';
import Vue from 'vue';
import ContentBlock from '@/core/shared/components/ContentBlock';
import BreadcrumbNavConnected from '@/core/shared/components/_connected/BreadcrumbNavConnected';
import UIButton from '@/core/shared/components/ui/UIButton';
import LaunchSettingsForm from '@/content/shared/LaunchSettingsForm';
import { ValidationObserver } from 'vee-validate';

@Component({
  components: {
    ContentBlock,
    UIButton,
    BreadcrumbNavConnected,
    LaunchSettingsForm,
    ValidationObserver,
  },
  props: {
    game: { required: true },
    storedGame: { required: true },
    contentOwnership: { },
  },
})
export default class ContentShowSettingsRoute extends Vue {
  get localGame () {
    return new Proxy(this.game, {
      get (obj, k) {
        return obj[k];
      },
      set: (obj, k, val) => {
        this.editGame(k, val);
        return true;
      },
    });
  }

  async editGame (k, val) {
    const isValid = await this.$refs.validationObserver.validate({ silent: true });
    this.$emit('edit', k, val, 'content-show-settings-route', isValid);
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.content-show-settings-route {
  @apply --container;
  padding-top: var(--spacingSm);
  @media (--desktop) {
    padding-top: var(--spacingMd);
  }
}
</style>
